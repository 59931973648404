export const columns = [
    {
      title: '归档流程名称',
      dataIndex: 'name',
      key: 'name',
      slots: { customRender: 'name' }
      // width: 100
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
      ellipsis: true,
      slots: { customRender: 'fileType' }
      // width: 100
    },
    {
      title: '适用范围',
      dataIndex: 'scope',
      key: 'scope',
      ellipsis: true,
      slots: { customRender: 'scope' }
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      slots: { customRender: 'action' },
      width: 120,
      align: 'left'
    }
  ]
  